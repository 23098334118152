// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-full-js": () => import("./../../../src/pages/blog-full.js" /* webpackChunkName: "component---src-pages-blog-full-js" */),
  "component---src-pages-blog-grid-js": () => import("./../../../src/pages/blog-grid.js" /* webpackChunkName: "component---src-pages-blog-grid-js" */),
  "component---src-pages-blog-list-js": () => import("./../../../src/pages/blog-list.js" /* webpackChunkName: "component---src-pages-blog-list-js" */),
  "component---src-pages-blog-single-js": () => import("./../../../src/pages/blog-single.js" /* webpackChunkName: "component---src-pages-blog-single-js" */),
  "component---src-pages-classic-js": () => import("./../../../src/pages/classic.js" /* webpackChunkName: "component---src-pages-classic-js" */),
  "component---src-pages-crypto-js": () => import("./../../../src/pages/crypto.js" /* webpackChunkName: "component---src-pages-crypto-js" */),
  "component---src-pages-get-no-obligation-quote-js": () => import("./../../../src/pages/get-no-obligation-quote.js" /* webpackChunkName: "component---src-pages-get-no-obligation-quote-js" */),
  "component---src-pages-hosting-js": () => import("./../../../src/pages/hosting.js" /* webpackChunkName: "component---src-pages-hosting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiry-js": () => import("./../../../src/pages/inquiry.js" /* webpackChunkName: "component---src-pages-inquiry-js" */),
  "component---src-pages-modern-js": () => import("./../../../src/pages/modern.js" /* webpackChunkName: "component---src-pages-modern-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-request-for-trial-js": () => import("./../../../src/pages/request-for-trial.js" /* webpackChunkName: "component---src-pages-request-for-trial-js" */)
}

